import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 发票记录 - 分页查询
 * @param params
 */
export function getPaymentList(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/charge/queryChargeList',
    method: 'get',
    params,
  });
}

/**
 * 发票记录 - 导出
 * @param params
 */
export function paymentListExport(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/charge/export',
    method: 'get',
    params,
  });
}

/**
 * 修改开票状态
 * @param params
 */
export function editInvoiceStatus(params) {
  return request({
    url: envInfo.bgApp.customerlegoPath + '/charge/editInvoiceStatus',
    method: 'post',
    data: params,
  });
}
